import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Home from '@mui/icons-material/Home';
import { usePages } from './PagesContext';
import PageMenu from './PageMenu';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../auth/AuthContext';


const useStyles = makeStyles((theme) => ({
list: {
  flexDirection: 'column',
  flexGrow: 1,
},
admin: {
  flex: 1,
  justifyContent: 'space-between'
}
}));

export default function MenuItemsList () {
 const { pages, currentPage } = usePages();
 const {currentUser} = useAuth();
 const [highlight, setHighlight] = useState(currentPage);
 const classes = useStyles();
 const user_access_pages = currentUser.claims.dashboards[0] === '*' ? pages.filter(page => !page.pageName.match("Admin Console")) : pages.filter(page => currentUser.claims.dashboards.includes(page.pageName));
 const pagesList = currentUser.claims.isAdmin === true ? pages : user_access_pages;

 const handleSelectedListItem = (pageId) => {
   setHighlight(pageId);
 };

 return (
  <React.Fragment className={classes.list}>
    {
          pagesList && pagesList.length > 0
            ? (pagesList.map((page) => (
              <PageMenu
                className={page.pageNumber === -1 ? classes.admin : null}
                highlight={highlight}
                handleSelectedListItem={handleSelectedListItem}
                page={page}
                key={page.pageUid}
              />
            ))
            )
            : (
              <ListItemButton
              component={Link}
              to="/">
                <ListItemIcon><Home /></ListItemIcon>
                <ListItemText primary="Home" />
              </ListItemButton>
            )
        }
  </React.Fragment>
 )
};