import React, {
    useContext, useState, useEffect, createContext,
} from 'react';
import { firestore } from '../../config/firebase';
import { collection, getDocs } from 'firebase/firestore';
import { useAuth } from '../auth/AuthContext';

const pagesContext = createContext();
export const usePages = () => useContext(pagesContext);

const makePagesTree = (pagesDocsList) => {
    const pagesUids = pagesDocsList.reduce((acc, page, curr) => {
        acc[page.pageUid] = curr;
        return acc;
    }, {});

    const pagesTree = [];
    let parentPage;
    pagesDocsList.forEach((page) => {
        if (page.parent === null) {
            parentPage = page;
            pagesTree.push(parentPage);
            return;
        }
        const parentPages = pagesDocsList[pagesUids[page.parent]];
        parentPages.children = [...(parentPages.children || []), page];
    });
    return {
        pagesTree,
    };
};

const getAllDashboards = async () => {
try {
    const dashboardsDocsP = await getDocs(collection(firestore, 'dashboards'));
    const dashboardsList = dashboardsDocsP.docs.map((doc) => doc.data());
    return {
    dashboardsList
    };
} catch (err) {
    console.error(err, "Error while fetching dashboards.");
    return { dashboardsDocList: null };
}
}

const getAllPagesForDashboard = async (dashboardUid) => {
    try {
        const dashboardsRef = collection(firestore, `dashboards/${dashboardUid}/pages`);
        const pagesDocsP = await getDocs(dashboardsRef);
        const pagesList = pagesDocsP.docs.map((doc) => doc.data());
        const pagesDocsList = pagesList.sort((a, b) => a.pageNumber - b.pageNumber);
        const pagesDocs = pagesDocsList
            .reduce((acc, curr) => {
                acc[curr.pageUid] = curr;
                return acc;
            }, {});
        return {
            pagesDocsList, pagesDocs
        };

    } catch (err) {
        console.error(err, "Error while fetching dashboard pages.");
        return { pagesDocsList: null, pagesDocs: null };
    }
};

const getAllPages = async () => {
    try {
        const { dashboardsList } = await getAllDashboards();
        let allPagesDocsList = [];
        dashboardsList.forEach(async (doc) => {
            const { pagesDocsList } = await getAllPagesForDashboard(doc.dashboardUid);
            allPagesDocsList.push(...pagesDocsList)
        })
        const allPagesDocs = allPagesDocsList
        .reduce((acc, curr) => {
            acc[curr.pageUid] = curr;
            return acc;
        }, {});
        return { allPagesDocsList, allPagesDocs }
    } catch (err) {
        console.error(err, "Error while fetching dashboard pages.");
        return {allPagesDocsList: null, allPagesDocs: null};
    }
}

const getPages = async () => {
    try {
        const { allPagesDocsList, allPagesDocs } = await getAllPages();
        const { pagesDocsList, pagesDocs } = await getAllPagesForDashboard('Recommend');
        const { pagesTree } = makePagesTree(allPagesDocsList);
        const defaultPage = Object.values(allPagesDocs).reduce((acc, curr) => {
            if (!acc || acc.pageNumber > curr.pageNumber) {
                return curr;
            }
            return acc;
        }, null);
        return {
            pagesTree, defaultPage, allPagesDocs, allPagesDocsList
        };
    } catch (err) {
        console.warn('Error while fetching the dashboard doc.');
        console.error(err);
        return { pagesTree: null, defaultPage: null, allPagesDocs: null, allPagesDocsList: null };
    }
};

export const useProvidePages = (auth) => {
    const [pages, setPages] = useState(null);
    const [flatPages, setFlatPages] = useState(null);
    const [dashPages, setDashPages] = useState(null);
    const [priming, setPriming] = useState(true);
    const [currentPage, setCurrentPage] = useState(null);

    const changeCurrentPage = (pageUid) => {
        setCurrentPage(pageUid);
    };

    useEffect(() => {
        const fetchPagesDocuments = async (auth) => {
            try {
                const { pagesTree, defaultPage, allPagesDocs, allPagesDocsList } = await getPages();
                if (pagesTree && allPagesDocs && allPagesDocsList ) {
                    setPages(pagesTree);
                    setDashPages(allPagesDocs);
                    setFlatPages(allPagesDocsList);
                } else {
                    setPages([]);
                    setDashPages({});
                    setFlatPages([]);
                }
                setCurrentPage(defaultPage?.pageUid);
                setPriming(false);
            } catch (error) {
                console.error(error, 'Error fetching documents.');
                setPriming(false);
            }
        };
        if (auth.fbUser) {
            fetchPagesDocuments();
        }
    }, [auth]);

    return {
        dashPages,
        pages,
        flatPages, 
        priming,
        currentPage,
        changeCurrentPage,
    };
};

export const ProvidePages = ({ children }) => {
    const auth = useAuth();
    const pages = useProvidePages(auth);
    return (
        <pagesContext.Provider value={pages}>
            {children}
        </pagesContext.Provider>
    );
};

export default ProvidePages;