import React from "react";
import { usePages } from "../dashboard/PagesContext";
import { Route, Routes } from "react-router-dom";
import DashboardContent from "../dashboard/Dashboard";
import Visualization from "../dashboard/Visualization";
import { useAuth } from '../auth/AuthContext';


export default function DefaultRoutes() {
    const { flatPages } = usePages();
    const currentPath = window.location.pathname;

    if (!flatPages) {
        return <div />;
      }
    
    return (
        <Routes>
            {flatPages.map((page, index) => (
                <Route
                exact={true}
                key={page.pageUid + index}
                path={page.path || currentPath}
                element={
                    <DashboardContent>
                        <Visualization currentPageObj={page} />
                    </DashboardContent>
                }
            />
            ))}
        </Routes>
    );
}