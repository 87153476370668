import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from '../auth/AuthContext';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
  },
  dashboardIframe: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
}));

export default function Visualization({ currentPageObj }) {
  const pageVisuals = currentPageObj?.visualizations[0];
  const { currentUser } = useAuth();
  const classes = useStyles();

  const params = {};
  for (let num = 0; num < 50; num++) {
    params[`ds${num}.current_user_email`] = currentUser.claims.email;
  }
  const stringifyParams = JSON.stringify(params);
  const encodedParams = encodeURIComponent(stringifyParams);

  if (!pageVisuals) {
    return <div />;
  }
  if (currentPageObj?.pageName === 'Admin Console') {
    return (
      <div className={classes.root}>
        <iframe
                  title={pageVisuals.visName}
                  className={classes.dashboardIframe}
                  src={`https://docs.google.com/spreadsheets/d/${pageVisuals.visUid}/edit#gid=0`}
                />
      </div>
    );
  }
  return (
    <div className={classes.root}>
      {
          currentPageObj?.visualizations && currentPageObj.visualizations.length > 0
            ? (
              <iframe
                title={pageVisuals.visName}
                className={classes.dashboardIframe}
                src={`https://lookerstudio.google.com/embed/reporting/${pageVisuals.visUid}/page/${pageVisuals.visPageUid}?params=${encodedParams}`}
              />
            )
            : (
              <div>
                <h1>
                  {`${currentPageObj?.pageName} does not currently exist for your organization`}
                </h1>
              </div>
            )
      }
    </div>
  );
}