import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ProvideAuth from './components/auth/AuthContext';
import ProvidePages from "./components/dashboard/PagesContext";
import Auth0Context from "./components/auth/Auth0Context";
import ProvideCustomAuth0 from "./components/auth/CustomAuth0Context";
import Auth0Wrapper from "./components/auth/Auth0Wrapper";
import DefaultRoutes from "./components/utils/DefaultRoutes";

function App() {
  return (
    <div>
      <BrowserRouter>
        <ProvideCustomAuth0>
          <Auth0Context>
            <Auth0Wrapper>
              <ProvideAuth>
                <ProvidePages>
                  <DefaultRoutes />
                </ProvidePages>
              </ProvideAuth>
            </Auth0Wrapper>
          </Auth0Context>
        </ProvideCustomAuth0>
      </BrowserRouter>
    </div>
  );
}

export default App;
